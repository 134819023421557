import {
    useGetAdsAccountsQuery,
    useGetMarkupSettingQuery, useGetMetaAdsAccountListQuery,
    useUpsertMarkupSettingMutation
} from "../../../generated/graphql";
import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid, Paper,
    Tab, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    Tabs,
    TextField,
    Typography
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {AdTypes, MyToggleButton} from "../template/CreateTemplateView";
import {endOfDay, startOfDay, eachDayOfInterval, format, startOfMonth, endOfMonth} from 'date-fns';
import NotificationPopup from "../../../common/NotificationPopup";


export default ({ isMeta = false }) =>
{
    const [open, setOpen] = useState(false);
    const { data:accounts, loading, error } = isMeta
        ? useGetMetaAdsAccountListQuery()
        : useGetAdsAccountsQuery();
    const [accountId, setAccountId] = useState<string>();
    const [selectedTab, setSelectedTab] = useState(isMeta ? 'meta' : AdTypes[0].key);

    const { data:markupSettingData } = useGetMarkupSettingQuery({
        variables: {
            accountId: accountId as string,
            adType: selectedTab,
        },
        skip: !accountId || !selectedTab,
    });

    useEffect(() => {
        // 当 markupSettingData 有值且包含 markupSetting 信息时更新表单
        if (markupSettingData && markupSettingData.getMarkupSettingByAccountAndType) {
            const { defaultMarkup, markupMap } = markupSettingData.getMarkupSettingByAccountAndType;
            setDefaultMarkup(defaultMarkup);
            setMarkupMap(markupMap);
        }
        else {
            resetForm();
        }
    }, [markupSettingData]);
    const resetForm = () => {
        setDefaultMarkup(100); // 重置为默认的 defaultMarkup 值
        setMarkupMap({}); // 清空 markupMap
        setStartTime(''); // 清空自定义的开始时间
        setEndTime(''); // 清空自定义的结束时间
        setCustomMarkup(100); // 重置自定义 markup 为默认值
    };

    const [upsertMarkupSetting] = useUpsertMarkupSettingMutation();

    const [defaultMarkup, setDefaultMarkup] = useState<number>(100);
    const [startTime, setStartTime] = useState<string>();
    const [endTime, setEndTime] = useState<string>();
    const [customMarkup, setCustomMarkup] = useState<number>(100);
    const [markupMap, setMarkupMap] = useState({});

    const handleApply = () => {
        if (startTime && endTime && customMarkup) {
            const startDate = startOfDay(new Date(startTime));
            const endDate = endOfDay(new Date(endTime));
            const dates = eachDayOfInterval({ start: startDate, end: endDate });

            const newMarkupMap = { ...markupMap };
            dates.forEach(date => {
                newMarkupMap[format(date, 'yyyy-MM-dd')] = customMarkup;
            });
            setMarkupMap(newMarkupMap);
        }
    };
    const renderTable = (markupMap) => {
        // 首先，将日期按月份分组
        const monthsMap = {};
        Object.keys(markupMap).forEach(date => {
            const month = format(new Date(date), 'yyyy-MM');
            if (!monthsMap[month]) {
                monthsMap[month] = [];
            }
            monthsMap[month].push(date);
        });

        const tableRows = Object.keys(monthsMap).sort((a, b) => b.localeCompare(a)).map(month => {
            const dates = monthsMap[month];
            const startDate = startOfMonth(new Date(dates[0]));
            const endDate = endOfMonth(new Date(dates[0]));
            const days = eachDayOfInterval({ start: startDate, end: endDate });

            const cells = days.map(day => {
                const dayFormatted = format(day, 'yyyy-MM-dd');
                const markup = markupMap[dayFormatted] || '-';
                return (
                    <TableCell key={dayFormatted} align="center" padding={'none'}>
                        {markup}
                    </TableCell>
                );
            });

            return (
                <TableRow key={month}>
                    <TableCell component="th" scope="row" align="center" padding={'none'} style={{padding: 5}}>
                        {format(new Date(dates[0]), 'yyyy-MM')}
                    </TableCell>
                    {cells}
                </TableRow>
            );
        });

        return (
            <TableContainer component={Paper}>
                <Table aria-label="custom markup table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ minWidth: 80, fontWeight: 'bold' }} align="center" padding={'none'}>年/月</TableCell>
                            {Array.from({ length: 31 }, (_, i) => (
                                <TableCell style={{ minWidth: 30, fontWeight: 'bold', padding: 5 }}  key={i} align="center" padding={'none'}>{i + 1}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const handleSave = async () => {
        if (!accountId) {
            NotificationPopup.error('請選擇廣告帳戶');
            return;
        }
        try {
            await upsertMarkupSetting({
                variables: {
                    markupSettingInput: {
                        accountId,
                        adType: selectedTab,
                        defaultMarkup,
                        markupMap,
                    },
                },
                refetchQueries: ["GetMarkupSetting"],
            });
            NotificationPopup.success("新增Markup紀錄成功")
        } catch (error) {
            console.error('Error upserting markup setting:', error);
        }
    };
    return <div>
        <Dialog maxWidth={"lg"} open={open} fullWidth={true}>
            <DialogTitle style={{ backgroundColor: "#DDD" }}>
                <Typography variant="h4" align="center">
                    設定帳戶Markup
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                        <Autocomplete
                            options={accounts?.[isMeta ? "metaAdsAccounts" : "adsAccounts"] ?? []}
                            getOptionLabel={(option:any) => `${option?.id}-${option?.name}`}
                            renderInput={(params) => <TextField {...params} label="廣告帳戶" />}
                            onChange={(_,value) =>{
                                setAccountId(value?.id as string);
                            }}
                        />
                    </Grid>
                    {!isMeta && (
                        <Grid item>
                            <Grid container item spacing={2}>
                                {AdTypes.map(({ key, label }) => (
                                    <Grid item key={key}>
                                        <MyToggleButton
                                            value="check"
                                            selected={key === selectedTab}
                                            onChange={() => setSelectedTab(key)}
                                        >
                                            {label}
                                        </MyToggleButton>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container spacing={2}>
                        <Grid item>
                            預設 Markup
                        </Grid>
                        <Grid item>
                            <TextField
                                type={"number"}
                                value={defaultMarkup}
                                InputProps={{
                                    inputProps: {
                                        style: {
                                            width: 80,
                                            textAlign: "right",
                                        },
                                    }
                                }}
                                onChange={({target})=>setDefaultMarkup(parseInt(target.value))}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} alignItems="center">
                        <Grid item>
                            自訂 Markup
                        </Grid>
                        <Grid item>
                            <TextField
                                type={"date"}
                                label={"開始時間"}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => {
                                    setStartTime(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type={"date"}
                                label={"結束時間"}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => {
                                    setEndTime(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type={"number"}
                                value={customMarkup}
                                InputProps={{
                                    inputProps: {
                                        style: {
                                            width: 80,
                                            textAlign: "right",
                                        },
                                    }
                                }}
                                onChange={({target})=>setCustomMarkup(parseInt(target.value))}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                onClick={handleApply}
                            >
                                套用
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {renderTable(markupMap)}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    結束
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={handleSave}
                >
                    儲存
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
        >
            設定帳戶Markup
        </Button>
    </div>
}